<template>
  <div>
    <v-dialog v-model="dialogCancelation" persistent max-width="900">
      <v-card>
        <v-toolbar>
          <v-btn icon dark @click="dialogCancelation = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar>Solicitar Cancelamento</v-toolbar>
        </v-toolbar>
        <v-container fluid>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <span>Motivo do Cancelamento</span>
                <v-text-field
                  solo
                  v-model="payloadCancelation.cancelationReason"
                  label="Motivo do Cancelamento"
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-checkbox
                  label="Contactou o CLIENTE sobre o cancelamento?"
                  v-model="payloadCancelation.contactCustomerCancelation"
                />
              </v-col>
              <span
                >Recomendamos solicitar o cancelamento, quando NÃO HOUVER
                possibilidade alguma de atender o pedido, e que antes se faça
                contato com o cliente. Lembrando que SOLICITAÇÕES DE
                CANCELAMENTO POR SUA PARTE, PODE AFETAR SUA AVALIAÇÃO NA
                PLATAFORMA</span
              >
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="dialogCancelation = false">
              FECHAR
            </v-btn>
            <v-btn color="green" text @click="cancelationCart"
              >SOLICITAR CANCELAMENTO
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog :fullscreen="fullScreen" v-model="cartDialog">
      <v-card>
        <v-toolbar flat style="padding-right: 10px !important">
          <v-toolbar-title>Pedido {{ cart.orderNumber }}</v-toolbar-title>
          <v-btn icon right absolute dark>
            <v-icon
              @click="fullScreen ? (fullScreen = false) : (fullScreen = true)"
              >{{ setIconScreen() }}</v-icon
            >
            <v-icon @click="cartDialog = false">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container>
          <v-row class="d-flex justify-center">
            <v-card width="98%" class="d-flex jusrify-center">
              <fieldset>
                <legend>Dados do Pedido</legend>
                <v-row>
                  <v-col cols="6">
                    <v-card-text>
                      <h6>Cliente:</h6>
                      {{ cart.customerName }}
                    </v-card-text>
                  </v-col>

                  <v-col cols="6">
                    <v-card-text>
                      <h6>Fone:</h6>
                      {{ cart.customerPhone }}
                    </v-card-text>
                  </v-col>

                  <v-col cols="6" class="mt-n8">
                    <v-card-subtitle>
                      <h6>Data Abertura:</h6>
                      {{ formatDate(cart.createdAt) }}
                    </v-card-subtitle>
                  </v-col>

                  <v-col cols="6" class="mt-n8">
                    <v-card-subtitle>
                      <h6>Data Fatura:</h6>
                      {{ formatDate(cart.closedDate) }}
                    </v-card-subtitle>
                  </v-col>
                  <v-col cols="6" class="mt-n8">
                    <v-card-subtitle>
                      <h6>Prazo Entrega:</h6>
                      {{ formatDate(cart.deadlineTime) }}
                    </v-card-subtitle>
                  </v-col>

                  <v-col cols="6" class="mt-n8">
                    <v-card-subtitle>
                      <h6>Forma de Pagamento:</h6>
                      {{
                        cart && cart.paymentMethod
                          ? cart.paymentMethod.name
                          : "-"
                      }}
                    </v-card-subtitle>
                  </v-col>
                  <v-col cols="12" class="mt-n8">
                    <v-card-subtitle>
                      <h6>Endereço Entrega:</h6>
                      {{ setAddress() }}
                    </v-card-subtitle>
                  </v-col>
                </v-row>
              </fieldset>
            </v-card>
          </v-row>

          <v-divider />

          <fieldset>
            <legend>Itens do Pedido</legend>
            <v-data-table
              dense
              :loading="loading"
              :headers="detailHeaders"
              :items="cart.items"
              :hide-default-footer="true"
            >
              <template v-slot:[`item.amountItems`]="{ item }">
                <span>R$ {{ formatPrice(item.amountItems) }}</span>
              </template>
            </v-data-table>

            <br />
            <v-row>
              <v-col cols="4">
                <v-card elevation="0" color="#3c3c3a">
                  <v-card-subtitle>Total Itens</v-card-subtitle>
                  <v-card-title class="mt-n8"
                    >R$ {{ formatPrice(cart.amountItems) }}</v-card-title
                  >
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card elevation="0" color="#3c3c3a">
                  <v-card-subtitle>Taxa Entrega</v-card-subtitle>
                  <v-card-title class="mt-n8"
                    >R$
                    {{
                      formatPrice(cart.deliveryRate ? cart.deliveryRate : 0.0)
                    }}</v-card-title
                  >
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card elevation="0" color="#3c3c3a">
                  <v-card-subtitle>Total do Pedido</v-card-subtitle>
                  <v-card-title class="mt-n8"
                    >R$ {{ formatPrice(cart.amountTotal) }}</v-card-title
                  >
                </v-card>
              </v-col>
            </v-row>
          </fieldset>
        </v-container>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false"
          >Ok</v-btn
        >
      </template>
    </v-snackbar>

    <v-container fluid>
      <v-row>
        <v-col cols="4">
          <v-card color="purple" min-height="800">
            <v-toolbar flat color="purple darken-1">
              <v-toolbar-title class="overline">Recebidos </v-toolbar-title>
            </v-toolbar>
            <div v-for="(one, oneIndex) in cartsOneStage" :key="oneIndex">
              <v-container fluid>
                <v-card>
                  <v-card-text>
                    <h6>Cliente:</h6>
                    {{ one.customerName }}

                    <h6>Prazo:</h6>
                    {{ formatDate(one.deadlineTime) }}

                    <h6>Informações:</h6>
                    {{ one.items.length }} Item - Total R$
                    {{ formatPrice(one.amountTotal) }}
                  </v-card-text>
                  <v-card-actions>
                    <v-btn @click="changeStage(one, 2)" x-small color="primary"
                      >Iniciar Preparo</v-btn
                    >
                    <v-btn @click="getCart(one)" x-small color="pink"
                      >Detalhes</v-btn
                    >
                    <v-btn
                      @click="openCancelationModal(one)"
                      x-small
                      color="red"
                      >Cancelar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-container>
            </div>
          </v-card>
        </v-col>

        <v-col cols="4">
          <v-card color="primary" min-height="800">
            <v-toolbar flat color="primary darken-1">
              <v-toolbar-title class="overline">Em Preparo </v-toolbar-title>
            </v-toolbar>
            <div v-for="(two, twoIndex) in cartsTwoStage" :key="twoIndex">
              <v-container fluid>
                <v-card>
                  <v-card-text>
                    <h6>Cliente:</h6>
                    {{ two.customerName }}

                    <h6>Prazo:</h6>
                    {{ formatDate(two.deadlineTime) }}

                    <h6>Informações:</h6>
                    {{ two.items.length }} Item - Total R$
                    {{ formatPrice(two.amountTotal) }}
                  </v-card-text>
                  <v-card-actions>
                    <v-btn @click="changeStage(two, 3)" x-small color="primary"
                      >Iniciar entrega</v-btn
                    >
                    <v-btn @click="getCart(two)" x-small color="pink"
                      >Detalhes</v-btn
                    >
                    <v-btn
                      @click="openCancelationModal(two)"
                      x-small
                      color="red"
                      >Cancelar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-container>
            </div>
          </v-card>
        </v-col>

        <v-col cols="4">
          <v-card color="success" min-height="800">
            <v-toolbar flat color="success darken-1">
              <v-toolbar-title class="overline"
                >Saiu para Entrega
              </v-toolbar-title>
            </v-toolbar>

            <div
              v-for="(three, threeIndex) in cartsThreeStage"
              :key="threeIndex"
            >
              <v-container fluid>
                <v-card>
                  <v-card-text>
                    <h6>Cliente:</h6>
                    {{ three.customerName }}

                    <h6>Prazo:</h6>
                    {{ formatDate(three.deadlineTime) }}

                    <h6>Informações:</h6>
                    {{ three.items.length }} Item - Total R$
                    {{ formatPrice(three.amountTotal) }}
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      @click="changeStage(three, 4)"
                      x-small
                      color="primary"
                      >Finalizar</v-btn
                    >
                    <v-btn @click="getCart(three)" x-small color="pink"
                      >Detalhes</v-btn
                    >
                    <v-btn
                      @click="openCancelationModal(three)"
                      x-small
                      color="red"
                      >Cancelar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-container>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import { VMoney } from "v-money";
import moment from "moment";
export default Vue.extend({
  name: "ShoppingCartDelivered",
  data: () => ({
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false /* doesn't work with directive */,
    },
    fullScreen: false,
    carts: [],
    cartsOneStage: [],
    cartsTwoStage: [],
    cartsThreeStage: [],
    cart: "",
    cartDialog: false,
    search: "",
    loading: false,
    dialogCancelation: false,
    payloadCancelation: {
      cancelationReason: "",
      contactCustomerCancelation: null,
    },
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    tributeId: "",

    headers: [
      {
        text: "Data Fatura",
        align: "start",
        value: "closedDate",
        filtering: true,
      },
      {
        text: "Número",
        align: "start",
        value: "orderNumber",
        filtering: true,
      },
      {
        text: "Status",
        value: "deliveryStatus",
        filtering: true,
      },
      {
        text: "Forma Pagamento",
        value: "paymentMethod",
        filtering: true,
      },
      {
        text: "Total R$",
        value: "amount",
        filtering: true,
      },

      { text: "Ações", value: "actions", filtering: false, sortable: false },
    ],

    detailHeaders: [
      {
        text: "Quantidade",
        align: "start",
        value: "quantity",
        filtering: true,
      },
      {
        text: "Produto",
        align: "start",
        value: "product.name",
        filtering: true,
      },
      {
        text: "Variação",
        align: "start",
        value: "size.name",
        filtering: true,
      },
      {
        text: "Total Item R$",
        value: "totalPrice",
        filtering: true,
      },
    ],
  }),
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    changeStage(cart, stage) {
      http.put(`delivery/sales/store/cart/${cart.id}/change-stage/${stage}`, {}).then(() => {
        this.getItems();
      });
    },
    openCancelationModal(cart) {
      this.cart = cart;
      this.dialogCancelation = true;
    },
    cancelationCart() {
      http
        .put(`delivery/sales/store/cart/${this.cart.id}/cancel`, this.payloadCancelation)
        .then(
          () => {
            this.snackbar.color = "success";
            this.snackbar.text = "Pedido CANCELADO COM SUCESSO!";
            this.snackbar.opened = true;
            this.openCancelationModal = false;
            this.getItems();
          },
          (err) => {
            const { response } = err;
            this.snackbar.color = "red";
            this.snackbar.text =
              "Hove um erro ao solicitar o CANCELAMENTO: " + response?.data?.message;
            this.snackbar.opened = true;
            this.openCancelationModal = false;
            this.getItems();
          }
        );
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    setIconScreen() {
      if (this.fullScreen) return "mdi-fullscreen-exit";
      else return "mdi-fullscreen";
    },
    setAddress() {
      const cart = this.cart;
      if (cart && cart.street)
        return `${cart.street}, ${cart.number} - ${cart.zone} - ${cart.city}/${cart.state}`;
      if (cart && cart.deliveryType === "onstore")
        return "Pedido será retirado na loja";
      return "Endereço não definido";
    },

    getCart(item) {
      this.cart = item;
      this.cartDialog = true;
    },

    getItems() {
      this.loading = true;
      http.get("delivery/sales/store/current-carts").then(
        (data) => {
          this.carts = data.data;
          this.cartsOneStage = this.carts.filter((d) => d.deliveryStage === 1);
          this.cartsTwoStage = this.carts.filter((d) => d.deliveryStage === 2);
          this.cartsThreeStage = this.carts.filter(
            (d) => d.deliveryStage === 3
          );
          this.loading = false;
        },
        (err) => {
          this.snackbar.color = "red";
          this.snackbar.text =
            "Hove um erro ao buscar os registros. Atualize a página por favor";
          this.snackbar.opened = true;
        }
      );
    },
  },
  directives: { money: VMoney },
  mounted() {
    this.getItems();
 /*   setInterval(() => {
      this.getItems();
    }, 15000);*/
  },
});
</script>
<style scoped>
fieldset {
  width: 97%;
  height: 90%;
  margin: auto;
  border-radius: 4px;
  padding: 15px;
}
</style>
